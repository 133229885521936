<template>
  <div style="height: 100%">
    <div class="div-main-list-page" :style="view === 'MAIN_CREDIT_AUTH' ? '' : 'height: fit-content'">
      <div class="div-main-list-page-title">
        <h1 v-if="view === 'MAIN_CREDIT_AUTH'">Credit Authorization</h1>
      </div>
      <div class=" flex-between-row div-main-list-page-tab-search-btn" style="margin-bottom: 0">
        <div>
          <el-tabs  v-if="view === 'MAIN_CREDIT_AUTH'"  class="tab-bar" v-model="viewCreditAuthTabActive" @tab-click="handleCreditAuthTabClick">
            <el-tab-pane label="Pending" name="PENDING"/>
            <el-tab-pane label="Approved" name="APPROVED"/>
            <el-tab-pane label="Rejected" name="REJECTED"/>
          </el-tabs>
           <h1 class="div-main-view-title" v-else-if="view === 'CREDIT_AUTH_VIEW_INVOICE'">Credit Authorization - View Invoice</h1>
           <h1  class="div-main-view-title"  v-else-if="view === 'CREDIT_AUTH_VIEW_ORDER'">Credit Authorization - View Order</h1>
        </div>
        <div class="flex-end">
          <el-button v-if="viewCreditAuthTabActive === 'PENDING' && hasPermission($permissionFunctionCode.CREDIT_APPROVAL)" round  outline type="success" @click="handleApprovalClick(true)">
            Approve
          </el-button>
          <el-button v-if="viewCreditAuthTabActive === 'PENDING' && hasPermission($permissionFunctionCode.CREDIT_APPROVAL)" round type="danger" @click="handleApprovalClick(false)">
            Reject
          </el-button>
          <el-button v-if="view !== 'MAIN_CREDIT_AUTH'" round  type="primary" style="margin-right: calc(2.5% + 20px)" @click="onHandleCloseFormPage">
            Close
          </el-button>
        </div>
      </div>
      <div v-if="view === 'MAIN_CREDIT_AUTH'" class="div-main-list-page-table">
        <el-table
        :data="creditAuthTableData"
        v-loading="loading"
        height="100%"
        :border="true"
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="CELL_STYLE"
        @selection-change="handleSelectionChange">
          <el-table-column v-if="viewCreditAuthTabActive === 'PENDING' && hasPermission($permissionFunctionCode.CREDIT_APPROVAL)" key="checkbox" type="selection" min-width="55"/>
          <el-table-column label="Cost" min-width="200px">
            <template v-slot="scope">
              <div>{{ `$${numberWithCommas(scope.row.currentAmount, 2)}`}}</div>
            </template>
          </el-table-column>
          <el-table-column label="Company" min-width="200px" show-overflow-tooltip>
            <template v-slot="scope">
              <div>{{scope.row.creditAssociation.counterpartyCompanyName}}</div>
            </template>
          </el-table-column>
          <el-table-column label="Type" min-width="150px">
            <template v-slot="scope">
              <div>{{ capitalizeFirstLetter(scope.row.type, '_') }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Vessel" min-width="150px">
            <template v-slot="scope">
              <div>{{ scope.row.vesselName ? scope.row.vesselName : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Vessel Imo" min-width="150px">
            <template v-slot="scope">
              <div>{{ scope.row.vesselImoNo ? scope.row.vesselImoNo : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Requested At" min-width="150px">
            <template v-slot="scope">
              <div>{{ $moment(scope.row.requestedAt).format('DD-MM-YYYY') }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Requested By" prop="requestorName" min-width="200px" show-overflow-tooltip/>
          <el-table-column label="Reason for Request" min-width="240px">
            <template v-slot="scope">
              <div style="word-break: break-word; margin: 8px 0;">{{scope.row.requestorRemark || '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column width="50px" fixed="right">
            <template v-slot="scope">
              <div class="div-main-table-menu-icon" @click="handleRightIconClick(scope.row)">
                <i class="el-icon-view" size="large"/>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="div-main-list-page-table-pagination">
          <el-pagination
            background
            @size-change="handleCreditAuthPageSizeChange"
            @current-change="handleCreditAuthPageChange"
            :page-size.sync="creditAuthPagination.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="creditAuthPagination.pageNumber"
            layout="total, sizes, prev, pager, next"
            :total="creditAuthPagination.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="credit-approval-form">
      <h1 style="margin-bottom: 32px;">Credit Remarks</h1>
      <p>{{creditAuthRequestSelected.length > 0 ? creditAuthRequestSelected[0].requestorRemark : ''}}</p>
      <hr style="margin: 40px 4px; border-top: 1px solid #E4E4E4;"/>
      <h1>{{view === 'CREDIT_AUTH_VIEW_ORDER' ? 'Order' : (view === 'CREDIT_AUTH_VIEW_INVOICE' ? 'Invoice' : 'Credit/Debit Note')}} Details</h1>
      <order-form
      v-if="view === 'CREDIT_AUTH_VIEW_ORDER'"
        :propsView="'VIEW_ORDER'" :orderType="orderType" isOrderSideBar isViewOnly :orderId="referenceId"
      />
      <invoice-form v-if="view === 'CREDIT_AUTH_VIEW_INVOICE'" isViewOnly propsView="VIEW_INVOICE" :invoiceId="referenceId" :orderType="orderType" :isSellerView="true"/>
    <cd-note-form v-if="view === 'CREDIT_AUTH_VIEW_CDNOTE'" isViewOnly propsView="EDIT_CD_NOTE" :cdNoteId="referenceId" :passedNoteType="orderType"
      @handleCloseCdFormCallBack="onHandleCloseFormPage"
    />
    </div>
    <!-- <order-form v-if="view === 'CREDIT_AUTH_VIEW_ORDER'" isOrderSideBar isViewOnly propsView="VIEW_ORDER" :orderId="referenceId" :orderType="orderType"/> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderForm from '@/views/order/components/OrderForm'
import InvoiceForm from '@/views/accounts/invoice/components/InvoiceForm.vue'
import CdNoteForm from '@/views/accounts/creditDebitNote/components/CdNoteForm'
import { formatDate } from '@/utils/dateTime.js'
import { numberWithCommas, capitalizeFirstLetter } from '@/utils'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
export default {
  name: 'CreditAuthorization',
  components: {
    OrderForm,
    InvoiceForm,
    CdNoteForm
  },
  data () {
    const creditAuthPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      view: 'MAIN_CREDIT_AUTH',
      loading: false,
      viewCreditAuthTabActive: 'PENDING',
      creditAuthTableData: [],
      creditAuthPagination,
      creditAuthRequestSelected: [],
      referenceId: null,
      orderType: null
    }
  },
  created () {
    this.getCreditAuthReqs(50, 0, this.viewCreditAuthTabActive)
  },
  computed: {
    ...mapState(['currentCompany'])
  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          setTimeout(() => {
            this.getCreditAuthReqs(this.creditAuthPagination.pageSize, 0, this.viewCreditAuthTabActive)
          }, 1000)
        }
      },
      immediate: false
    }
  },
  methods: {
    numberWithCommas,
    capitalizeFirstLetter,
    formatDate,
    handleCreditAuthTabClick (tab) {
      this.getCreditAuthReqs(this.creditAuthPagination.pageSize, 0, tab.name)
    },
    handleRightIconClick (row) {
      this.creditAuthRequestSelected = [row]
      switch (row.type) {
        case 'ORDER':
          this.view = 'CREDIT_AUTH_VIEW_ORDER'
          this.referenceId = row.orderId
          break
        case 'CREDIT_NOTE':
        case 'DEBIT_NOTE':
          this.view = 'CREDIT_AUTH_VIEW_CDNOTE'
          this.referenceId = row.noteId
          this.orderType = row.type
          break
        case 'INVOICE':
          this.view = 'CREDIT_AUTH_VIEW_INVOICE'
          this.referenceId = row.invoiceId
          break
      }
    },
    handleSelectionChange (selection) {
      this.creditAuthRequestSelected = selection
    },
    handleApprovalClick (isApproved) {
      if (this.view === 'MAIN_CREDIT_AUTH') {
        if (this.creditAuthRequestSelected.length === 0) {
          this.$message.error('Please select at least one item for approval.')
          return
        }
      }
      this.$request.post({
        url: this.$apis.creditAuthorizationRequestsApproval,
        data: {
          creditAuthorizationRequestIds: this.creditAuthRequestSelected.map(item => item.id),
          isApproved
        }
      }).then(res => {
        if (res?.code === 1000) {
          isApproved ? this.$message.success('Credit has been approved.') : this.$message.success('Credit has been rejected.')
          if (this.view === 'MAIN_CREDIT_AUTH') {
            this.getCreditAuthReqs(this.creditAuthPagination.pageSize, 0, this.viewCreditAuthTabActive)
          } else {
            this.onHandleCloseFormPage()
          }
        }
      })
    },
    onHandleCloseFormPage () {
      this.view = 'MAIN_CREDIT_AUTH'
      this.getCreditAuthReqs(50, 0, this.viewCreditAuthTabActive)
    },
    handleCreditAuthPageChange (pageNumber) {
      this.getCreditAuthReqs(this.creditAuthPagination.pageSize, pageNumber - 1, this.viewCreditAuthTabActive)
    },
    handleCreditAuthPageSizeChange (pageSize) {
      this.getCreditAuthReqs(pageSize, 0, this.viewCreditAuthTabActive)
    },
    getCreditAuthReqs (pageSize, pageNumber, status) {
      this.loading = true
      this.$request.get({
        url: `${this.$apis.creditAuthorizationRequests}?pageSize=${pageSize}&pageNumber=${pageNumber}&filter=status:'${status}'&sort=updatedAt desc`
      }).then(res => {
        if (res?.code === 1000) {
          this.creditAuthTableData = res.data.content
          this.creditAuthPagination.total = res.data.totalElements
          this.creditAuthPagination.pageNumber = res.data.pageable.pageNumber + 1
          this.loading = false
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/styles/index";
  .div-form-main {
    height: calc(100% - 40px);
  }
  .div-cd-note-main {
    height: calc(100% - 20px);
  }
  .credit-approval-form {
    overflow-y: scroll;
    height: calc(100% - 40px);
    >h1 {
      margin: 0;
      margin-top: 20px;
      // color: #7EB1F3;
    }
    >p {
      margin-left: 10px;
    }
  }
/deep/.div-form-main-container {
  height: fit-content;
}
/deep/.div-form-main-container {
  overflow-y: hidden !important;
}
</style>

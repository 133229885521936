var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      {
        staticClass: "div-main-list-page",
        style: _vm.view === "MAIN_CREDIT_AUTH" ? "" : "height: fit-content",
      },
      [
        _c("div", { staticClass: "div-main-list-page-title" }, [
          _vm.view === "MAIN_CREDIT_AUTH"
            ? _c("h1", [_vm._v("Credit Authorization")])
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "flex-between-row div-main-list-page-tab-search-btn",
            staticStyle: { "margin-bottom": "0" },
          },
          [
            _c(
              "div",
              [
                _vm.view === "MAIN_CREDIT_AUTH"
                  ? _c(
                      "el-tabs",
                      {
                        staticClass: "tab-bar",
                        on: { "tab-click": _vm.handleCreditAuthTabClick },
                        model: {
                          value: _vm.viewCreditAuthTabActive,
                          callback: function ($$v) {
                            _vm.viewCreditAuthTabActive = $$v
                          },
                          expression: "viewCreditAuthTabActive",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "Pending", name: "PENDING" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "Approved", name: "APPROVED" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "Rejected", name: "REJECTED" },
                        }),
                      ],
                      1
                    )
                  : _vm.view === "CREDIT_AUTH_VIEW_INVOICE"
                  ? _c("h1", { staticClass: "div-main-view-title" }, [
                      _vm._v("Credit Authorization - View Invoice"),
                    ])
                  : _vm.view === "CREDIT_AUTH_VIEW_ORDER"
                  ? _c("h1", { staticClass: "div-main-view-title" }, [
                      _vm._v("Credit Authorization - View Order"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-end" },
              [
                _vm.viewCreditAuthTabActive === "PENDING" &&
                _vm.hasPermission(_vm.$permissionFunctionCode.CREDIT_APPROVAL)
                  ? _c(
                      "el-button",
                      {
                        attrs: { round: "", outline: "", type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApprovalClick(true)
                          },
                        },
                      },
                      [_vm._v(" Approve ")]
                    )
                  : _vm._e(),
                _vm.viewCreditAuthTabActive === "PENDING" &&
                _vm.hasPermission(_vm.$permissionFunctionCode.CREDIT_APPROVAL)
                  ? _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleApprovalClick(false)
                          },
                        },
                      },
                      [_vm._v(" Reject ")]
                    )
                  : _vm._e(),
                _vm.view !== "MAIN_CREDIT_AUTH"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "calc(2.5% + 20px)" },
                        attrs: { round: "", type: "primary" },
                        on: { click: _vm.onHandleCloseFormPage },
                      },
                      [_vm._v(" Close ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm.view === "MAIN_CREDIT_AUTH"
          ? _c(
              "div",
              { staticClass: "div-main-list-page-table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: {
                      data: _vm.creditAuthTableData,
                      height: "100%",
                      border: true,
                      "header-cell-style": _vm.HEADER_CELL_STYLE,
                      "cell-style": _vm.CELL_STYLE,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _vm.viewCreditAuthTabActive === "PENDING" &&
                    _vm.hasPermission(
                      _vm.$permissionFunctionCode.CREDIT_APPROVAL
                    )
                      ? _c("el-table-column", {
                          key: "checkbox",
                          attrs: { type: "selection", "min-width": "55" },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: { label: "Cost", "min-width": "200px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      `$${_vm.numberWithCommas(
                                        scope.row.currentAmount,
                                        2
                                      )}`
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        315573266
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Company",
                        "min-width": "200px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.creditAssociation
                                        .counterpartyCompanyName
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        956563057
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Type", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.capitalizeFirstLetter(
                                        scope.row.type,
                                        "_"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3661558443
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Vessel", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.vesselName
                                        ? scope.row.vesselName
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3552275161
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Vessel Imo", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.vesselImoNo
                                        ? scope.row.vesselImoNo
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3330069401
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "Requested At", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$moment(scope.row.requestedAt)
                                        .format("DD-MM-YYYY")
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4093487757
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Requested By",
                        prop: "requestorName",
                        "min-width": "200px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Reason for Request",
                        "min-width": "240px",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "word-break": "break-word",
                                      margin: "8px 0",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.requestorRemark || "-")
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4045135641
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "50px", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "div-main-table-menu-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRightIconClick(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-view",
                                      attrs: { size: "large" },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1022106569
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "div-main-list-page-table-pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.creditAuthPagination.pageSize,
                        "page-sizes": [5, 10, 20, 50, 100],
                        "current-page": _vm.creditAuthPagination.pageNumber,
                        layout: "total, sizes, prev, pager, next",
                        total: _vm.creditAuthPagination.total,
                      },
                      on: {
                        "size-change": _vm.handleCreditAuthPageSizeChange,
                        "current-change": _vm.handleCreditAuthPageChange,
                        "update:pageSize": function ($event) {
                          return _vm.$set(
                            _vm.creditAuthPagination,
                            "pageSize",
                            $event
                          )
                        },
                        "update:page-size": function ($event) {
                          return _vm.$set(
                            _vm.creditAuthPagination,
                            "pageSize",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "credit-approval-form" },
      [
        _c("h1", { staticStyle: { "margin-bottom": "32px" } }, [
          _vm._v("Credit Remarks"),
        ]),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.creditAuthRequestSelected.length > 0
                ? _vm.creditAuthRequestSelected[0].requestorRemark
                : ""
            )
          ),
        ]),
        _c("hr", {
          staticStyle: {
            margin: "40px 4px",
            "border-top": "1px solid #E4E4E4",
          },
        }),
        _c("h1", [
          _vm._v(
            _vm._s(
              _vm.view === "CREDIT_AUTH_VIEW_ORDER"
                ? "Order"
                : _vm.view === "CREDIT_AUTH_VIEW_INVOICE"
                ? "Invoice"
                : "Credit/Debit Note"
            ) + " Details"
          ),
        ]),
        _vm.view === "CREDIT_AUTH_VIEW_ORDER"
          ? _c("order-form", {
              attrs: {
                propsView: "VIEW_ORDER",
                orderType: _vm.orderType,
                isOrderSideBar: "",
                isViewOnly: "",
                orderId: _vm.referenceId,
              },
            })
          : _vm._e(),
        _vm.view === "CREDIT_AUTH_VIEW_INVOICE"
          ? _c("invoice-form", {
              attrs: {
                isViewOnly: "",
                propsView: "VIEW_INVOICE",
                invoiceId: _vm.referenceId,
                orderType: _vm.orderType,
                isSellerView: true,
              },
            })
          : _vm._e(),
        _vm.view === "CREDIT_AUTH_VIEW_CDNOTE"
          ? _c("cd-note-form", {
              attrs: {
                isViewOnly: "",
                propsView: "EDIT_CD_NOTE",
                cdNoteId: _vm.referenceId,
                passedNoteType: _vm.orderType,
              },
              on: { handleCloseCdFormCallBack: _vm.onHandleCloseFormPage },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }